import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GetEventsOverview, GetEventsOverviewVariables } from 'src/graphql-types/GetEventsOverview';
import getSortedEvents from 'src/utils/helper/events';

import { tournamentsClient } from '../../apollo/client';
import { GetEvents_tournament_events as Event } from '../../graphql-types/GetEvents';
import AdminTable, { DataCols } from '../admin-table/admin-table';
import Button from '../button/button';
import { GET_EVENTS_OVERVIEW } from '../events/events-queries';
import { Footer, Modal } from '../modal/modal';
import Spinner from '../spinner/spinner';
import { EventName } from '../tournament-attributes/tournament-attributes';
import { SortableTournaments } from '../tournaments/tournaments';
import * as styles from './tournament-events-modal.module.less';

interface TournamentEventsModalProps {
  show: boolean;
  tournament: SortableTournaments | undefined;
  closeEventsModal: () => void;
}
const TournamentEventsModal: React.FC<TournamentEventsModalProps> = ({ show, tournament, closeEventsModal }) => {
  const { t } = useTranslation();

  const { loading, data } = useQuery<GetEventsOverview, GetEventsOverviewVariables>(GET_EVENTS_OVERVIEW, {
    client: tournamentsClient,
    variables: { id: tournament?.id },
  });

  const events = useMemo(() => getSortedEvents(data?.tournament?.events), [data?.tournament?.events]);

  const cols: DataCols<Event> = useMemo(() => {
    const columns: DataCols<Event> = [
      {
        key: 'event',
        title: t('event'),
        getValue: (e) => <EventName event={e} />,
      },
      {
        key: 'players',
        title: t('players'),
        getValue: (e) => e?.formatConfiguration?.drawSize,
      },
    ];

    return columns;
  }, [t]);

  return (
    <Modal show={show} sectionClassname={styles.section}>
      <h3 className={styles.modalTitle}>{tournament?.name}</h3>
      <p className={styles.eventsCount}>
        {tournament?.eventCount} {tournament && tournament?.eventCount > 1 ? t('events') : t('event')}
      </p>
      {loading && <Spinner />}
      {!loading && events && (
        <div className={styles.body}>
          <AdminTable columns={cols} data={events} />
        </div>
      )}
      <Footer>
        <Button level="tertiary" onClick={closeEventsModal}>
          {t('close')}
        </Button>
      </Footer>
    </Modal>
  );
};

export default TournamentEventsModal;
