import React from 'react';

import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import Tournaments from 'src/components/tournaments/tournaments';
import { usePrivateRoute } from 'src/utils/auth';

const IndexPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Tournaments" />
      {/* <NavBar /> */}
      <Tournaments />
    </Layout>
  );
};

export default IndexPage;
