import React from 'react';

import { useTranslation } from 'react-i18next';

import { GroupingType } from '../../graphql-types/globalTournamentTypes';
import Dropdown from '../dropdown/dropdown';

interface GroupByFilterProps {
  onGroupByChange: (value: GroupingType) => void;
  value: GroupingType;
}

const GroupByFilter: React.FC<GroupByFilterProps> = ({ onGroupByChange, value }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dropdown
        selected={value}
        options={[
          { label: t('week'), value: GroupingType.WEEK },
          { label: t('month'), value: GroupingType.MONTH },
          { label: t('ungrouped'), value: GroupingType.ALL },
        ]}
        onSelect={(o) => onGroupByChange(o.value)}
      />
    </div>
  );
};

export default GroupByFilter;
