import React, { SetStateAction, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { DataCols } from '../admin-table/admin-table';
import DropdownContainer from '../dropdown-custom/dropdown-container';
import { Checkbox } from '../formik-fields/formik-fields';
import { SortableTournaments } from '../tournaments/tournaments';

interface TournamentTableColumnsProps {
  columns: DataCols<SortableTournaments>;
  setCols: React.Dispatch<SetStateAction<any[]>>;
}

const TournamentTableColumns: React.FC<TournamentTableColumnsProps> = ({ columns, setCols }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const toggleDropdown = (value: boolean) => {
    setIsOpen(value);
  };

  const handleChange = (key: string, state: boolean) => {
    const updatedCols = columns.map((col) => {
      if (col.key === key)
        return {
          ...col,
          columnToggle: { ...col.columnToggle, checked: state },
        };
      return col;
    });
    setCols(updatedCols);
  };

  return (
    <div style={{ margin: '0 10px' }}>
      <DropdownContainer
        type="button"
        buttonIconName="sm-columns"
        label={t('columns')}
        isOpen={isOpen}
        icon="calendar-icon"
        iconPosition="left"
        onToggleDropdown={toggleDropdown}
        grayedLabel={true}
        width="auto"
      >
        {columns?.map(({ title, key, columnToggle }) => (
          <div key={key}>
            <Checkbox
              key={key}
              label={title}
              checked={columnToggle?.checked}
              onChecked={(state: boolean) => handleChange(key, state)}
            />
          </div>
        ))}
      </DropdownContainer>
    </div>
  );
};

export default TournamentTableColumns;
